<template>
  <div class="vd_login">
    <div class="vd_main">
      <div class="vd_main_box">
        <el-row class="vd_card_o">
          <el-card :body-style="{ padding: '0px' }" class="vd_card">
            <div class="vd_centerwei">
              <img src="../assets/image/dian.png" alt="" class="vg_cursor" style="width: 100px" v-if="!loginMode" @click="switchMode1()" />
              <img src="../assets/image/ka.jpg" alt="" class="vg_cursor" style="width: 100px" v-else @click="switchMode2()" />
            </div>
            <el-row>
              <el-col :span="12" class="vd_bg">
                <div class="vd_inner_box">
                  <el-row class="vd_word vd_inner_title">
                    <span class="vd_text">
                      <h2>上海满恩MES系统</h2>
                    </span>
                  </el-row>
                  <el-row class="vd_word vd_inner_content">
                    <span class="vd_text"> 上海满恩宠物用品有限公司版权所有 2021年05月08日 </span>
                  </el-row>
                </div>
              </el-col>
              <el-col :span="12">
                <div v-show="isLogin">
                  <div class="vd_center vd_title vd_0e932e" v-if="!loginMode">刷卡登录</div>
                  <div class="vd_center vd_title vd_409eff" v-else>账号登录</div>
                  <el-form ref="login" :model="loginForm" v-if="loginMode">
                    <el-row>
                      <!-- <el-col class="vg_mt_16 vd_user_input">
                        <el-form-item prop="user_factory">
                          <el-input
                              v-model="loginForm.acct_no"
                              show-word-limit
                              placeholder="请输入账套编号"
                              @input ="loginForm.acct_no = helper.keepEngNum(loginForm.acct_no)"
                              class="vd_login_input">
                            <i slot="prefix" class="el-input__icon el-icon-s-order"></i>
                          </el-input>
                        </el-form-item>
                      </el-col> -->
                      <el-col class="vg_mt_16 vd_user_input">
                        <el-form-item prop="user_name">
                          <el-input
                            v-model="loginForm.user_name"
                            show-word-limit
                            @input="loginForm.user_name = helper.keepEngNumABar(loginForm.user_name)"
                            maxlength="16"
                            placeholder="请输入用户名（仅限英文和数字和@和-）"
                            class="vd_login_input"
                          >
                            <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="vg_mt_16 vd_user_input">
                        <el-form-item prop="user_pass">
                          <el-input
                            v-model="loginForm.user_pass"
                            minlength="6"
                            maxlength="16"
                            placeholder="请输入密码(首次登录输入初始密码）"
                            class="vd_login_input"
                            @input="loginForm.user_pass = helper.keepEngNum(loginForm.user_pass)"
                            show-password
                            clearable
                            @keyup.enter.native="changePassword"
                          >
                            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                  <div v-else>
                    <el-row>
                      <el-col class="vg_mt_16 vd_user_input1">
                        <!-- <el-form-item> -->
                        <el-input
                          ref="elInputLogin"
                          v-model="switchModeLogin"
                          placeholder="请刷卡"
                          class="vd_login_input"
                          clearable
                          maxlength="10"
                          show-word-limit
                          @keyup.enter.native="chooseLogin"
                          @input="switchModeLogin = helper.keepTNum(switchModeLogin)"
                        >
                          <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                        </el-input>
                        <!-- </el-form-item> -->
                      </el-col>
                    </el-row>
                  </div>
                  <div class="vd_center vg_mt_16">
                    <el-button type="primary" class="vd_login_button" @click="chooseLogin" v-if="loginMode">登录</el-button>
                    <!-- <el-button
                      type="success"
                      class="vd_login_button"
                      @click="chooseLogin"
                      v-else
                      >登录</el-button
                    > -->
                  </div>
                </div>
                <div v-show="isChange">
                  <div class="vd_center vd_title">重置初始密码</div>
                  <el-form ref="changeForm" :model="changeForm" :rules="rules" label-width="156px">
                    <el-row>
                      <el-col class="vg_mt_16">
                        <el-form-item label="密码" prop="user_pass">
                          <el-input
                            v-if="passWordFlag"
                            v-model="changeForm.user_pass"
                            minlength="6"
                            maxlength="16"
                            placeholder="新密码必须包含字母和数字"
                            class="vd_login_input"
                            show-password
                            clearable
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="vg_mt_16">
                        <el-form-item label="确认密码" prop="user_pass2">
                          <el-input
                            v-if="passWordFlag"
                            v-model="changeForm.user_pass2"
                            minlength="6"
                            maxlength="16"
                            placeholder="请确认新密码"
                            class="vd_login_input"
                            show-password
                            clearable
                            @keyup.enter.native="submitForm('changeForm')"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                  <div class="vd_center">
                    <el-button type="primary" class="vd_login_button" @click="submitForm('changeForm')">确认修改</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
          <div class="foots">
            <div style="display: flex">
              <el-image :src="require('@assets/logo2.png')" style="width: 21px; height: 21px"></el-image>
              <el-link :underline="false" class="vg_ml_8 vg_cursor" @click="jumpGG">沪公网安备31010402335060号</el-link>
              <el-link :underline="false" class="vg_ml_8 vg_cursor" @click="jumpG">沪ICP备16001718号-7</el-link>
            </div>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { userAPI } from '@api/modules/user';

export default {
  name: 'Login',
  data() {
    let checkPwd = (rule, value, callback) => {
      const pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      if (!value) {
        return callback(new Error('密码不能为空'));
      } else {
        if (pwdReg.test(value)) {
          callback();
        } else {
          callback(new Error('请输入正确的格式'));
        }
      }
    };
    //验证密码
    let checkPwd2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请确认密码'));
      } else if (value !== this.changeForm.user_pass) {
        callback(new Error('两次密码不一致'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        acct_no: '',
        user_name: '',
        user_pass: ''
      },
      switchModeLogin: null,
      changeForm: {
        user_id: null,
        user_pass: '',
        user_pass2: ''
      },
      isLogin: true,
      isChange: false,
      rules: {
        user_pass: [{ required: true, validator: checkPwd, trigger: 'blur' }],
        user_pass2: [{ required: true, validator: checkPwd2, trigger: 'blur' }]
      },
      userInfo: {},
      push: [],
      loginMode: true,
      passWordFlag: true
    };
  },
  created() {
    this.getVersion();
  },
  methods: {
    getVersion() {
      get(userAPI.getVersion)
        .then(res => {
          if (res.data.code === 0) {
            if (sessionStorage.getItem('loaded') !== res.data.data.version + '') {
              sessionStorage.setItem('loaded', res.data.data.version + '');
              window.location.reload();
            }
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 挑选刷卡选项
    chooseLogin() {
      if (this.loginMode) {
        this.changePassword();
      } else {
        this.switchModeChange();
      }
    },
    jumpG() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index');
    },
    jumpGG() {
      window.open('https://beian.mps.gov.cn/#/query/webSearch');
    },
    // 刷卡登录
    switchModeChange() {
      post(userAPI.readCardLogin, { rfid_no: this.switchModeLogin })
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '登录成功'
            });
            res.data.data.form.user.rfid_no = res.data.data.rfid_no;
            this.$cookies.set('userInfo', res.data.data.form.user, 60 * 60 * 24 * 7);
            this.$cookies.set('push', JSON.stringify(res.data.data.form.push), 60 * 60 * 24 * 7);
            this.$cookies.set('userMode', { type: res.data.data.form.user.user_mode }, 60 * 60 * 24 * 7);
            this.jump('/dashboard');
          } else {
            this.switchModeLogin = null;
            let mg = res.data.msg;
            let er = 'error';
            this.$message({ message: mg, type: er });
            setTimeout(() => {
              this.$refs.elInputLogin.focus();
            }, 100);
          }
        })
        .catch(() => {
          this.switchModeLogin = null;
          this.$message({ message: '卡号错误，请重新输入', type: 'error' });
          setTimeout(() => {
            this.$refs.elInputLogin.focus();
          }, 100);
        });
    },
    //跳转修改密码
    changePassword() {
      post(userAPI.userLogin, this.loginForm)
        .then(res => {
          if (res.data.code === 0) {
            this.userInfo = res.data.data.user;
            this.push = res.data.data.push;
            if (this.userInfo.update_time === 0) {
              // 初次登录
              this.$message({
                type: 'success',
                message: '请修改初始密码'
              });
              this.isLogin = false;
              this.isChange = true;
              this.changeForm.user_pass = null;
              this.changeForm.user_pass2 = null;
            } else {
              // 正常跳转首页
              this.$cookies.set('userInfo', this.userInfo, 60 * 60 * 24 * 30);
              this.$cookies.set('push', this.push, 60 * 60 * 24 * 30);
              var mode = { type: res.data.data.user.user_mode };
              this.$cookies.set('userMode', mode, 60 * 60 * 24 * 30);
              this.jump('/dashboard');
            }
          } else if (res.data.code === 4) {
            let mg = '请重新点击登录';
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.deleteCookie();
          } else {
            //登录不成功
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 删除cookie
    deleteCookie() {
      get(userAPI.userLogout)
        .then(res => {
          if (res.data.code === 0) {
            this.$cookies.remove('userInfo');
            this.$cookies.remove('push');
            this.$cookies.remove('userMode');
          }
        })
        .catch(() => {});
    },
    // 切换登录方式
    switchMode1() {
      this.loginMode = true;
    },
    switchMode2() {
      this.loginMode = false;
      if (!this.loginMode) {
        setTimeout(() => {
          this.$refs.elInputLogin.focus();
        }, 100);
      }
    },
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.passWordFlag = false;
          this.doLogin();
        } else {
          console.log('error submit!');
          return false;
        }
      });
    },
    //真·登录
    doLogin() {
      this.changeForm.user_id = this.userInfo.user_id;
      post(userAPI.resetUserPass, this.changeForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '修改成功，请重新登录'
            });
            this.doLogout();
            this.$nextTick(() => {
              this.passWordFlag = true;
            });
            // this.isChange = false
            // this.isLogin = true
            // this.loginForm.user_pass = ''
          }
        })
        .catch(() => {});
    },
    doLogout() {
      get(userAPI.userLogout)
        .then(res => {
          if (res.data.code === 0) {
            this.isChange = false;
            this.isLogin = true;
            this.loginForm.user_pass = '';
          }
        })
        .catch(() => {});
    }
  },
  mounted() {
    if (this.$cookies.get('loginType')) {
      this.loginMode = false;
      this.$cookies.remove('loginType');
    }
  }
};
</script>

<style scoped lang="scss">
.vd_login {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.vd_main {
  position: relative;
  height: 100vh;
  margin: 0 auto;
}
.vd_card {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  width: 100%;
}
.vd_bg {
  width: 528px;
  height: 511px;
  position: relative;
  background: url('~@/assets/image/bg-img.png') no-repeat center/cover;
}
.vd_inner_box {
  height: 100%;
  background: rgba(64, 158, 255, 0.5);
}
.vd_father {
  display: flex;
  align-items: center;
}
.vd_word {
  width: 100%;
  color: $color-white;
}
.vd_inner_title {
  position: absolute;
  top: 40%;
}
.vd_inner_content {
  position: absolute;
  bottom: 5%;
}
.vd_text {
  display: flex;
  justify-content: center;
}
.vd_center {
  text-align: center;
}
.vd_centerwei {
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.vd_title {
  font-size: 24px;
  margin-top: 80px;
  margin-bottom: 30px;
  font-weight: 550;
  color: $color_primary;
}
.vd_login_button {
  width: 75%;
  margin-top: 30px;
}
//.vd_user_input {
//  display: flex;
//  justify-content: center;
//}
.vd_login_input {
  width: 60%;
}
.vd_forget {
  margin-top: 16px;
  margin-right: 16px;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
}
.el-input__icon {
  color: $color-primary;
}
.vd_user_input ::v-deep.el-form-item__content {
  display: flex;
  justify-content: center;
}
.vd_user_input1 {
  display: flex;
  justify-content: center;
}
.vd_0e932e {
  color: #0e932e;
}
.vd_409eff {
  color: #409eff;
}
::v-deep .el-form-item__error {
  font-size: 12px;
}
.header {
  position: absolute;
  width: 100%;
  height: 50%;
  background: #008dbd;
  box-shadow: 0 10px 28px rgb(119, 175, 211);
}

.vd_card_o {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1056px;
}
.foots {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: -50px;
  left: 0;
  box-sizing: border-box;
}
</style>
